header {
	background-color: #fff;
	z-index: 2;
	width: 100%;
	padding: 20px;
	.wrap-header {
		display: flex;
		justify-content: space-between;
		position: relative;
		align-items: center;
		padding: 10px 0px;
		.logo {
			img {
				width: 200px;
			}
		}
		.mobile-menu-wrap {
			display: flex;
			align-items: center;
			.user-mobile-search-bar {
				display: flex;
				align-items: center;
				margin-right: 20px;
				.search-icon {
					margin-left: 10px;
					color: #5353a2;
				}
			}
		}
	}
}
@media (max-width: 992px) {
	header .wrap-header {
		.logo img {
			width: 200px;
		}
		.user-search-bar {
			display: none;
		}
	}
}
@media (max-width: 780px) {
	header .wrap-header {
		.logo img {
			width: 160px;
		}
	}
}