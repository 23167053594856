.wrapper {margin-bottom: 20px;}
.wrap-form {
	width: 100%;
	text-align: justify;
	border-radius: 5px;
	margin: 5px 0;
	padding: 15px;
	background-color: #fff;
	border: 1px solid #fff;
	box-shadow: 1px 3px 10px -4px #9b98a1;
	font-weight: 600px;
	.page-title {color: #49405f;}

	label, input, select, textarea, select {
		margin-top: 10px;
	}

	input, select, textarea, select {
		&:focus {
			border: 1px solid #ced4da;
			box-shadow: 0 0 0 0.25rem #567f8e38;
		}
	}

	.info-message{
		color: #646366;
		font-weight: 400;
	}

	select {
		border: 1px solid #ced4da;
		border-radius: .25rem;
		width: 100%;
	}

	.image-preview {
		display: flex;
		margin-top: 10px;
		img {
			max-width: 100%;
			max-height: 320px;
		}
	}

	.wrap-tags {
		display: flex;
		justify-content: space-between;
		text-align: center;
		input{margin: 0px 5px;}
	}
}

.cropper-wrapper {
	display: flex;
	max-width: 100%;
	.img-preview {
		width: 100%;
	}
	
}

.primary-btn {
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: #fff;
	background-color: #6363ad;
	padding: 8px 12px;;
	border: 1px solid #6363ad;
	border-radius: 5px;
	text-decoration: none;
	margin-top: 10px;
	font-weight: bold;
	&:hover {background-color: #6363ad;}
}

.message {
	font-style: italic;
	font-size: 14px;
	.error {color: red;}
	.success {color: green;}
}
