.profile-container {
	width: 100%;
	background-color: var(--cover-color);
	--primary-color: hsl(var(--font-color), 25%); 
	.wrap-profile {
		width: 100%;
		min-height: 250px;
		padding: 50px 0;
		.avatar {
			text-align: end;
			img {
				width: 150px;
				height: 150px;
				padding: 4px;
				border-radius: 10px;
			}
		}
		.user-data {
			height: 100%;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: space-between;
			color: var(--primary-color);
			.wrap-basic-info {
				.wrap-action {
					display: flex;
					align-items: center;
					.username {
						font-size: 30px;
						font-weight: 600;
					}
					.edit-btn {
						cursor: pointer;
						font-weight: 600;
						border-radius: 10px;
						margin-left: 40px;
					}
				}
				.description-profile {
					color: var(--primary-color);
					margin-top: 20px;
					font-size: 15px; 
				}
			}
			.follows-info {
				display: flex;
				justify-content: space-between;
				div {
					cursor: pointer;
					font-weight: 600;
					&:first-of-type {
						margin-right: 40px;
					}
				}
			}
		}
		.follow-btn {
			display: flex;
			align-items: center;
			cursor: pointer;
			margin-left: 40px;
			padding: 6px;
			border-radius: 10px;
			font-size: 13px;
			font-weight: bold;
			color: var(--primary-color);
			outline: none;
			border: 1px solid var(--primary-color);
		}
	}
}
.sad-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 300px 300px;
	font-size: 30px;
	color: #567f8e;
	.error {font-size: 48px;}
}
@media screen and (max-width: 750px) {
	.sad-container {
		padding: 200px 0px;
	}
}
@media screen and (max-width: 993px) {
	.profile-container {
		.wrap-profile {
			.avatar {
				text-align: center;
			}
			.user-data  {
				align-items: center;
				text-align: center;
				.wrap-action {
					justify-content: center;
				}
			}
		}
	}
}