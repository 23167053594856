.wrapper { margin-bottom: 20px;}
.timeline-spinner {text-align: center;}
.end-message {
	margin-top: 20px;
	text-align: center;
	color: #567f8e;
	div {color: #9b9a9a;}
}
.timeline-msg {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 300px 300px;
	font-size: 30px;
	color: #567f8e;
	a {
		color: #567f8e;
		font-weight: 600;
		&:hover {text-decoration: underline;}
	}
}
@media (max-width: 995px) {
	.delete-post-mobile {
		display: block !important;
	}
	.timeline-msg {
		padding: 200px 0px;
	}
	.delete-post {display: none;}
}
