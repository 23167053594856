.wrap-edit-post-form {
	display: flex;
	flex-direction: column;
	.page-title {color: #49405f;}

	label, input, select, textarea, select {
		margin-top: 10px;
	}

	input, select, textarea, select {
		&:focus {
			border: 1px solid #ced4da;
			box-shadow: 0 0 0 0.25rem #567f8e38;
		}
	}

	.info-message{
		color: #646366;
		font-weight: 400;
	}

	select {
		border: 1px solid #ced4da;
		border-radius: .25rem;
		width: 100%;
	}

	.checkbox {
		display: inline-flex;
		cursor: pointer;
		position: relative;
	}

	.checkbox > span {
		color: black;
		padding: 0.5rem 0.25rem;
	}

	.checkbox > input {
		height: 25px;
		width: 25px;
		-webkit-appearance: none;
		-moz-appearance: none;
		-o-appearance: none;
		appearance: none;
		border: 1px solid #f2eeee;
		border-radius: 50%;
		outline: none;
		transition-duration: 0.3s;
		cursor: pointer;

		&:hover {
			transform: scale(1.3);
		}
	}

	.checkbox > input:checked + span::before {
		content: '\2713';
		display: block;
		color: #ccc;
		position: absolute;
		left: 6px;
		top: 11px;
	}
	
	.checkbox > input:active {
		border: 2px solid #34495E;
	}
}

.primary-btn {
	color: #fff;
	background-color: #6363ad;
	padding: 8px 12px;;
	border: 1px solid #6363ad;
	border-radius: 5px;
	text-decoration: none;
	margin-top: 10px;
	font-weight: bold;
	&:hover {background-color: #6363ad;}
}

.message {
	font-style: italic;
	font-size: 14px;
	.error {color: red;}
	.success {color: green;}
}
