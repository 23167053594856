.post-image {
	position: relative;
	img {
		width: 400px;
		object-fit: contain;
		border-radius: 10px;
		box-shadow: 1px 3px 10px -4px #c1ccd5;
	}
	.delete-post {
		opacity: 0;
		position: absolute;
		top: 20px;
		left: 40%;
		button {
			padding: 6px 12px;
			color: #fff;
			background: #990d15;
			border: 1px solid #990d15;
			border-radius: 10px;
		}
	}
	.delete-post-mobile {
		display: none;
		position: absolute;
		top: 20px;
		left: 20px;
		i {
			color: #990d15;
		}
	}
	&:hover {
		.delete-post {opacity: 1;}
	}
}

.vertical-timeline-element-icon {
	span {
		font-size: 14px;
		text-align: center;
		mix-blend-mode: difference;
	}
}