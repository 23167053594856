.post-image {
	position: relative;
	img {
		width: 400px;
		object-fit: contain;
		border-radius: 10px;
		box-shadow: 1px 3px 10px -4px #c1ccd5;
	}
}

.vertical-timeline-element-icon {
	span {
		font-size: 14px;
		text-align: center;
		mix-blend-mode: difference;
	}
}