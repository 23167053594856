.likes-wrapper {
    display: flex;
    align-items: center;
	.like-btn {
		color: #567f8e;
		font-size: 20px;
		margin-right: 10px;
		cursor: pointer;
		animation: .5s linear burst; 
	}
}