.menu-button-wrapper {
	display: flex;
	align-items: center;
	.icon-wrapper {
		.hamburger-lines {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			height: 26px;
			width: 32px;
			z-index: 2;
			.line {
				display: block;
				height: 4px;
				width: 100%;
				border-radius: 10px;
				background-color: #5353a2;
			}
			.line1 {
				transform-origin: 0% 0%;
				transition: transform 0.4s ease-in-out;
			}
			.line2 {
				transition: transform 0.2s ease-in-out;
			}
			.line3 {
				transform-origin: 0% 100%;
				transition: transform 0.4s ease-in-out;
			}
		}
	}
	.hamburger-menu-container {
		position: fixed;
		top: 12px;
		left: 0;
		bottom: 0;
		right: 0;
		height: 100vh;
		width: 100vw;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 2;
		.wrap-list {
			z-index: 3;
			.item-list {
				border-left: 1px solid #e0e0e0;
				border-right: 1px solid #e0e0e0;
				border-bottom:  1px solid #e0e0e0;
				background: #fff;
				position: absolute;
				z-index: 4;
				width: 90%;
				top: 4.9vh;
				left: 4%;
				display: flex;
				flex-direction: column;
				padding: 15px 0;
				border-radius: 0 0 5px 5px;
				text-align: center;
				user-select: none;
				transform: translateX(-50%) scale(0);
				transform-origin: center;
				transition: all 0.4s cubic-bezier(0.54, -0.10, 0.57, 0.57);
				a, .item {
					cursor: pointer;
					font-weight: 600;
					color: #5353a2; 
					padding: 10px 25px;
					text-decoration: none;
					word-spacing: -4px;
					&:hover {
						width: 100%;
						background-color: #e9ecef;
					}
				}

				.item-search {
					max-width: 90%;
					margin: 0px 30px;
				}
			}
		}
	}
	.checkbox {
		position: absolute;
		display: block;
		height: 32px;
		width: 32px;
		z-index: 5;
		opacity: 0;
		cursor: pointer;

		&:checked ~ .hamburger-menu-container .wrap-list .item-list {
			transform: translateX(0);
			opacity: 1;
			user-select: auto;
		}  
		&:checked ~ .icon-wrapper .hamburger-lines .line1 {
			transform: rotate(45deg);
		}
		&:checked ~ .icon-wrapper .hamburger-lines .line2 {
			transform: scaleY(0);
		}
		&:checked ~ .icon-wrapper .hamburger-lines .line3 {
			transform: rotate(-45deg);
		}
	}
}