.follow {
	margin-bottom: 20px;
	a {
		display: flex;
		align-items: center;
		color: black;
		text-decoration: none;
	}
	img {
		background-color: #f1f1f1;
		width: 45px;
		border-radius: 50%;
		margin-right: 10px;
	}
}

.title {
	text-transform: capitalize;
}