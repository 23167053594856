// $colors: #a3328c, #ca295b, #dd2445, #fd231f, #fd6631, #fcae44;
$colors: #49405F, #504973, #565286, #5D5B9A, #6363AD, #9492A6;
@mixin WidthAndHeight($w,$h) {
	width: $w;
	height: $h;
}

.loader-wrapper {
	.loader {
		position: absolute;
		top: 50%;
		left: 40%;
		margin-left: 10%;
		transform: translate3d(-50%, -50%, 0);
	}
	background: #eee;
	.loader {
		.dot {
			width: 20px;
			height: 20px;
			background: #eee;
			display: inline-block;
			border-radius: 50%;
			animation: scale 2s infinite;
			@for $i from 1 through length($colors) {
				&:nth-child(#{length($colors)}n + #{$i}) {
					background: nth($colors, $i);
					animation-delay: 0.1s * $i;
				}
			}
		}
	}
}

@keyframes scale {
	0% {
		transform: scale(1);
	}
	50% {
		opacity: 0.3;
		transform: scale(2);
	}
	100% {
		transform: scale(1);
	}
}