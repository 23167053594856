@import url('https://fonts.googleapis.com/css2?family=Cookie&family=Open+Sans:ital,wght@1,300&family=Roboto+Mono:wght@100;400&display=swap');

.wrap-nav {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	.nav-item {
		color: #5353a2;
		background: none;
		border: none;
		border-radius: 5px;
		text-decoration: none; 
		margin: 0px 10px;
		font-weight: 600;
		cursor: pointer;
		position: relative;
		&:before {
			background-color: #5353a2;
			position: absolute;
			content: "";
			width: 0;
			height: 2px;
			bottom: -1px;
			left: 0;
			transition: 0.3s;
		}
		&:hover::before {
			width: 100%;
		}
		&:focus {
			box-shadow: none;
		}
	}
}

.nav-link {
	color: #49405f;
	text-decoration: none;
	font-weight: 600;
	cursor: pointer;
	&:hover {color: #49405f;}
	&:active {background: #bfb7d3;}
}


@media (max-width: 992px) {
	.wrap-nav {display: none !important;}
}