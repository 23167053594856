.wrap-form {
	label, input, select, textarea, select, .cover-color-wrap {
		margin-bottom: 20px;
	}

	input, select, textarea, select {
		&:focus {
			border: 1px solid #ced4da;
			box-shadow: 0 0 0 0.25rem #567f8e38;
		}
	}
}

.checkbox {
	cursor: pointer;
	display: inline-flex;
	position: relative;
}

.checkbox > span {
	color: black;
	padding: 0.5rem 0.25rem;
}

.checkbox > input {
	height: 25px;
	width: 25px;
	-webkit-appearance: none;
	-moz-appearance: none;
	-o-appearance: none;
	appearance: none;
	border: 1px solid #909090;
	border-radius: 50%;
	outline: none;
	transition-duration: 0.3s;
	cursor: pointer;

	&:hover {
		transform: scale(1.3);
	}
}

.checkbox > input:checked + span::before {
	content: '\2713';
	display: block;
	color: #247927;
	position: absolute;
	left: 7px;
    top: 3px;
}

.checkbox > input:active {
	border: 2px solid #34495E;
}

.primary-btn {
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: #fff;
	background-color: #6363ad;
	padding: 8px 12px;;
	border: 1px solid #6363ad;
	border-radius: 5px;
	text-decoration: none;
	margin-top: 10px;
	font-weight: bold;
	&:hover {background-color: #6363ad;}
}