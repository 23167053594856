.wrap-comments {
	background-color:#fff;
    border-radius: 0px 0px 5px 5px;
	padding: 20px;

	.comments-container {
		max-height: 250px;
		overflow-y: auto;
		.wrap-comment {
			margin-bottom: 20px;
			.comment {
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				.comment-username {
					color: #49405f;
					font-weight: 600;
					text-decoration: none;
					margin-right: 10px;
					img {
						background-color: #f1f1f1;
						width: 32px;
						border-radius: 50%;
						margin-right: 10px;
					}
				}
			}
			.comment-action {
				cursor: pointer;
				font-size: small;
				color: #ababab;
				&:hover{
					text-decoration: underline;
					text-underline-position: under;
				}
			}
		}
	}

	.comment-form {
		.form-textarea {
			width: 100%;
			border: none;
			background: #567f8e05;
			padding: 5px 10px;
			height: 50%;
			border-radius: 5px 5px 0px 0px;
			border-bottom: 2px solid #6363ad;
		}
	
		.comment-button {
			background: #6363ad;
			color: #fff;
			padding: 10px 10px;
			border: none;
			margin-top: 0px;
			cursor: pointer;
			border-radius: 5px;
			font-weight: 600;
			&:hover {
				background-color: #49405f;
			}
		}
	}
}